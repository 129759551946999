import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { SharedService } from './shared/services/shared.service';
import { UPUtilityService } from 'up';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private upUtilityService: UPUtilityService,
    private translate: TranslateService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const isAuthorized = this.upUtilityService.hasAuthorizedAccess(
      route?.data,
      this.sharedService.getRole(),
      this.sharedService.getLtiaAction()
    );
    if (!isAuthorized) {
      this.router.navigate(['/unauthorized'], {
        state: {
          deleteUrl: `${environment.apiUrl}/go`,
          pageTitle:
            this.translate.instant('GENERIC.UNAUTHORIZED.PAGE_TITLE') +
            this.translate.instant('GENERIC.PAGE_TITLE'),
          isIframeLaunch:
            this.sharedService.getLtiaAction() === 'select' ? true : false,
        },
      });
    }
    return isAuthorized;
  }
}
