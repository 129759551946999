import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpBackend,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './shared/components/header/header.component';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuidLibModule } from '@mhe/quid';
import { IndeterminateDirective } from './shared/directive/indeterminate/indeterminate.directive';
import { SharedModule } from './shared/shared.module';
import { ErrorComponent } from './shared/components/error/error.component';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { InterceptorErrorComponent } from './shared/interceptors/interceptor-error/interceptor-error.component';
import { RelinkLaunchComponent } from './instructor/components/relink-launch/relink-launch.component';
import { UPModule, ConfigService, launchConfigs, ltiParams } from 'up';
import { SharedService } from './shared/services/shared.service';
import { environment } from 'src/environments/environment';
export function createTranslateLoader(httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(httpBackend, [
    './assets/quid-i18n/',
    './assets/i18n/',
    './assets/up-i18n/',
  ]);
}

export function initializeApplication(
  config: ConfigService,
  sharedService: SharedService
) {
  return (): Promise<void> => {
    const launchConfigs: launchConfigs = {
      createTokenApiUrl: `${environment.apiUrl}/up/jwt/token/create`,
      paramsApiUrl: `${environment.apiUrl}/go/params`,
      redirectUrl: 'https://www.mheducation.com/highered/go',
      environmentName: environment.envName,
    };
    return config.processRequest(launchConfigs).then(
      (ltiResponse: ltiParams) => {
        sharedService.setLtiParams(ltiResponse);
      },
      () => {
        sharedService.navigateToErrorPage(
          'INSTRUCTOR.PRODUCT_SELECTION.ERROR_MESSAGE'
        );
      }
    );
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ErrorComponent,
    IndeterminateDirective,
    InterceptorErrorComponent,
    RelinkLaunchComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend],
      },
    }),
    NgbModule,
    QuidLibModule,
    UPModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    { provide: Window, useValue: window },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      deps: [ConfigService, SharedService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
