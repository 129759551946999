import { EventEmitter, Injectable, Output } from '@angular/core';
import { Pagination } from 'up';

@Injectable({
  providedIn: 'root',
})
export class PaginationService {
  @Output() paginationDataEvent = new EventEmitter<Pagination>();
  @Output() learnerOnClickPreviousEvent = new EventEmitter<Pagination>();
  @Output() learnerOnClickNextEvent = new EventEmitter<Pagination>();
  @Output() instructorOnClickPreviousEvent = new EventEmitter<Pagination>();
  @Output() instructorOnClickNextEvent = new EventEmitter<Pagination>();

  paginateObject!: Pagination;

  constructor() {}

  paginationData(params: Pagination) {
    this.paginateObject = params;
    this.paginationDataEvent.emit(params);
  }

  getPaginateData() {
    return this.paginateObject;
  }

  emitOnClickPreviousEvent(role: string) {
    role == 'instructor'
      ? this.instructorOnClickPreviousEvent.emit()
      : this.learnerOnClickPreviousEvent.emit();
  }

  emitOnClickNextEventEvent(role: string) {
    role == 'instructor'
      ? this.instructorOnClickNextEvent.emit()
      : this.learnerOnClickNextEvent.emit();
  }
}
