<nav
  [attr.aria-label]="'LEARNER.ASSIGNMENT_PLAYER.QUESTION' | translate"
  class="previous-next-container">
  <div class="container-fluid">
    <hr
      aria-hidden="true"
      *ngIf="userRole === 'instructor' && customAction === 'select'" />
    <div class="row align-middle pagination-wrapper-height">
      <div
        class="col-md-12 col-lg-12 d-flex align-items-center justify-content-center">
        <div>
          <button
            [attr.aria-label]="'LEARNER.ASSIGNMENT_PLAYER.PREVIOUS' | translate"
            class="previous-button mhe-icon"
            [disabled]="isPreviousButtonDisabled"
            (click)="onClickPrevious($event)"
            data-automation-id="question-previous-btn"
            type="button">
            <mhicon-chevron-left size="lg"></mhicon-chevron-left>
          </button>
        </div>
        <div
          class="ms-3 me-3 font-28"
          data-automation-id="question-number-index-text">
          <strong>
            <span class="visually-hidden">
              {{ "LEARNER.ASSIGNMENT_PLAYER.QUESTION" | translate }}
            </span>
            {{ currentPageIndex }}
          </strong>
          {{ "LEARNER.ASSIGNMENT_PLAYER.OF" | translate }} {{ questionCount }}
        </div>
        <div>
          <button
            [attr.aria-label]="'LEARNER.ASSIGNMENT_PLAYER.NEXT' | translate"
            class="next-button mhe-icon"
            [disabled]="isNextButtonDisabled"
            (click)="onClickNext($event)"
            data-automation-id="question-next-btn"
            type="button">
            <mhicon-chevron-right size="lg"></mhicon-chevron-right>
          </button>
        </div>
      </div>
    </div>
  </div>
</nav>
