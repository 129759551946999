import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './shared/components/error/error.component';
import { AuthGuard } from './auth.guard';
import { UPUnauthorizedComponent } from 'up';
const routes: Routes = [
  {
    path: 'products',
    loadChildren: () =>
      import('./instructor/instructor.module').then((m) => m.InstructorModule),
    canActivate: [AuthGuard],
    data: { authorizedRole: 'instructor' },
  },
  {
    path: 'ebook',
    loadChildren: () =>
      import('./learner/learner.module').then((m) => m.LearnerModule),
    canActivate: [AuthGuard],
    data: { authorizedRole: 'learner' },
  },
  {
    path: 'unauthorized',
    component: UPUnauthorizedComponent,
  },
  {
    path: '',
    component: AppComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
