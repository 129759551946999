import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuidLibModule } from '@mhe/quid';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationFooterComponent } from './components/pagination-footer/pagination-footer.component';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { UPModule } from 'up';
@NgModule({
  declarations: [PaginationFooterComponent],
  imports: [
    CommonModule,
    QuidLibModule,
    TranslateModule,
    NgbToastModule,
    NgbModule,
    FormsModule,
    UPModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [UPModule, PaginationFooterComponent],
})
export class SharedModule {}
