import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import '@mhe/mh-icon/src/icon-components/x-circle';
import { TranslateService } from '@ngx-translate/core';
import { GenericError } from 'up/lib/interfaces/access.interface';
import '@mhe/mh-icon/src/icon-components/chevron-down';
import { UPUtilityService, TECH_SUPPORT_CENTER_URL } from 'up';
@Component({
  selector: 'go-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  errorMessageKey: string = 'GENERIC.ERROR.MESSAGE';
  isCollapse = true;
  genericError!: GenericError;
  errorSupportMessage!: string;
  constructor(
    private sharedService: SharedService,
    private translate: TranslateService,
    private upUtilityService: UPUtilityService
  ) {}

  ngOnInit() {
    this.translate.get('GENERIC.ERROR.PAGE_TITLE').subscribe({
      next: (translatedPageTitle) => {
        this.upUtilityService.setPageTitle(translatedPageTitle);
      },
    });
    const translationKey = this.sharedService.getErrorPageTranslationKey();
    if (translationKey) {
      this.errorMessageKey = translationKey;
      this.sharedService.clearErrorPageTranslationKey();
    }
  }

  renderDetailedError() {
    const genericErrorResponse: GenericError = this.sharedService.getApiError();
    if (
      genericErrorResponse.status !== 0 ||
      genericErrorResponse.message !== ''
    ) {
      this.genericError = genericErrorResponse;
      this.errorSupportMessage = this.translate.instant(
        'GENERIC.ERROR.ERROR_SUPPORT_MESSAGE',
        {
          url:
            "<a target='_blank' href='" +
            TECH_SUPPORT_CENTER_URL +
            "ContactUs?dc=Instructor_Resources'><span class='link'>" +
            this.translate.instant('GENERIC.ERROR.ERROR_SUPPORT_MESSAGE_LINK') +
            '</span></a>',
        }
      );
    }
  }

  onExitClick() {
    this.sharedService.onExit();
  }
}
