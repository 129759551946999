import { EventEmitter, Injectable, Output } from '@angular/core';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root',
})
export class AttemptService {
  isNewAttemptStarted: boolean = false;
  attemptId!: number;
  constructor(public sharedService: SharedService) {}
  @Output() newAttemptClickEvent = new EventEmitter<boolean>();

  getRemainingAttempts() {
    return this.sharedService.ltiParams.remainingAttempts!;
  }

  getMaxAttempts() {
    return this.sharedService.ltiParams.maxAttempts!;
  }

  decreaseRemainingAttempts() {
    this.sharedService.ltiParams.remainingAttempts =
      this.sharedService.ltiParams.remainingAttempts! - 1;
  }

  getLatestAttempts() {
    return this.sharedService.ltiParams.latestAttemptNo!;
  }

  increaseLatestAttempts() {
    this.sharedService.ltiParams.latestAttemptNo =
      this.sharedService.ltiParams.latestAttemptNo! + 1;
  }

  getLatestAttemptStatus() {
    return this.sharedService.ltiParams.latestAttemptStatus;
  }

  setLatestAttemptStatus(attemptStatus: string) {
    this.sharedService.ltiParams.latestAttemptStatus = attemptStatus;
  }

  onClickNewAttempt() {
    this.newAttemptClickEvent.emit(true);
  }
  setNewAttemptStarted(isNewAttempt: boolean) {
    this.isNewAttemptStarted = isNewAttempt;
  }

  getNewAttemptStarted() {
    return this.isNewAttemptStarted;
  }

  getAssignmentCompleteStatus() {
    return this.sharedService.ltiParams.hasAssignmentCompleted!;
  }

  setAssignmentCompleteStatus(completeStatus: boolean) {
    this.sharedService.ltiParams.hasAssignmentCompleted = completeStatus;
  }

  isMultipleAttempts() {
    return this.sharedService.ltiParams.maxAttempts! > 1;
  }

  updateMultipleAttemptsConfig() {
    this.sharedService.ltiParams.hasAssignmentCompleted = true;
    this.sharedService.ltiParams.latestAttemptStatus = 'SUBMITTED';
    this.sharedService.ltiParams.remainingAttempts = 0;
  }

  setAttemptIdResultView(attemptId: number) {
    this.attemptId = attemptId;
  }

  getAttemptIdResultView() {
    return this.attemptId;
  }
}
