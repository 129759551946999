import { Component, OnDestroy, OnInit } from '@angular/core';
import { PaginationService } from 'src/app/shared/services/pagination.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import '@mhe/mh-icon/src/icon-components/chevron-left';
import '@mhe/mh-icon/src/icon-components/chevron-right';
import { Pagination } from 'up';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'go-pagination-footer',
  templateUrl: './pagination-footer.component.html',
  styleUrls: ['./pagination-footer.component.scss'],
})
export class PaginationFooterComponent implements OnInit, OnDestroy {
  isPreviousButtonDisabled!: boolean;
  isNextButtonDisabled!: boolean;
  currentPageIndex!: number;
  questionCount!: number;
  userRole: string = '';
  customAction: string = '';
  lastStateOfPreviousButton!: boolean;
  lastStateOfNextButton!: boolean;
  private destroy$ = new Subject<boolean>();
  constructor(
    private paginationService: PaginationService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    let isSubscriptionSuccess = false;
    this.userRole = this.sharedService.getRole();
    this.customAction = this.sharedService.getLtiaAction();

    this.paginationService.paginationDataEvent.subscribe((data: Pagination) => {
      isSubscriptionSuccess = true;
      this.setPaginationData(data);
    });

    if (!isSubscriptionSuccess) {
      const data = this.paginationService.getPaginateData();
      this.setPaginationData(data);
    }

    this.sharedService
      .getItemUpdateApiStatus()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (isItemUpdateApiInProgress: boolean) => {
          if (isItemUpdateApiInProgress) {
            this.isPreviousButtonDisabled = true;
            this.isNextButtonDisabled = true;
          } else {
            this.isPreviousButtonDisabled = this.lastStateOfPreviousButton;
            this.isNextButtonDisabled = this.lastStateOfNextButton;
          }
        },
      });
  }

  setPaginationData(data: Pagination) {
    this.isPreviousButtonDisabled = data?.isPreviousButtonDisabled!;
    this.currentPageIndex =
      data?.role === 'instructor'
        ? data?.currentPageIndex
        : data?.currentPageIndex + 1;
    this.questionCount = data?.count;
    this.isNextButtonDisabled = data?.isNextButtonDisabled!;
    this.lastStateOfPreviousButton = this.isPreviousButtonDisabled;
    this.lastStateOfNextButton = this.isNextButtonDisabled;
  }

  onClickPrevious(event: Event) {
    this.removeFocus(event);
    this.paginationService.emitOnClickPreviousEvent(this.userRole);
  }

  onClickNext(event: Event) {
    this.removeFocus(event);
    this.paginationService.emitOnClickNextEventEvent(this.userRole);
  }

  removeFocus(event: Event) {
    (event.target as HTMLInputElement).blur();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
